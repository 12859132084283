<form [formGroup]="form">
  <div formGroupName="char">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput placeholder="Name" formControlName="name" maxlength="50" minlength="3"
        pattern="^[A-Za-zñÑáéíóúÁÉÍÓÚ ]*$">
      <!-- <button mat-raised-button color=" primary" matSuffix>Random</button> -->


    </mat-form-field>

    <div class="form-error" *ngIf="form.get('char.name').errors?.name == false">A character with this name already
      exists or is an unacceptable name. </div>
    <div class="form-error" *ngIf="form.get('char.name').errors?.pattern">Your name can't be a number 🤦‍♂️.
    </div>
    <div class="form-error" *ngIf="form.get('char.name').errors?.minlength">Your name needs to be a minimum of 3
      characters.</div>

    <mat-radio-group color="primary" formControlName="gender">
      <mat-radio-button value="Male" radioGroup="radioGender">Male</mat-radio-button>
      <mat-radio-button value="Female" radioGroup="radioGender">Female</mat-radio-button>
    </mat-radio-group>

  </div>
</form>

<form [formGroup]="form">
  <div formGroupName="bodyType">
    <div class="form-controls">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Build</mat-label>
          <mat-select formControlName="body">
            <mat-option *ngFor="let data of bodyTypes$" [value]="data.name">
              {{data.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>Skin Colour</mat-label>
          <mat-select formControlName="skinColor">
            <mat-option *ngFor="let data of skinColorTypes$" [value]="data.name">
              {{data.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </div>
</form>
<!-- <h3>Facial Features</h3> -->
<form [formGroup]="form">
  <div formGroupName="facialFeatures">
    <div class="form-controls">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Facial Features</mat-label>
          <mat-select formControlName="face">
            <mat-option *ngFor="let data of facialTypes$" [value]="data.name">
              {{data.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Eye Colour</mat-label>
          <mat-select formControlName="eyeColor">
            <mat-option *ngFor="let data of eyeColorTypes$" [value]="data.name">
              {{data.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>

<!-- <h3>Hair</h3> -->
<form [formGroup]="form">
  <div formGroupName="hair">
    <div class="form-controls">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Hair Colour</mat-label>
          <mat-select formControlName="hairColor">
            <mat-option *ngFor="let data of hairColorTypes$" [value]="data.name">
              {{data.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Hair Length</mat-label>
          <mat-select formControlName="hairLength">
            <mat-option *ngFor="let data of hairLengthTypes$" [value]="data.name">
              {{data.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-controls">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Hair Texture</mat-label>
          <mat-select formControlName="hairTexture">
            <mat-option *ngFor="let data of hairTextureTypes$" [value]="data.name">
              {{data.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="this.form?.get('char.gender').value == '' || this.form?.get('char.gender').value == 'Male'">
        <mat-form-field appearance="outline">
          <mat-label>Facial Hair</mat-label>
          <mat-select formControlName="facialHair">
            <mat-option *ngFor="let data of facialHairTypes$" [value]="data.name">
              {{data.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </div>
</form>
