<header>
    <img class="logo" src="/assets/images/archaic-quest.png" alt="ArchaicQuest logo Text based game" />
    <h1>Character Creation</h1>
</header>
<div class="create-character-wrapper">



    <mat-horizontal-stepper class="create-character-section" [linear]="true" #stepper>
        <mat-step [stepControl]="raceForm">
            <form [formGroup]="raceForm">
                <div class="create-character-container">
                    <ng-template matStepLabel>Race</ng-template>
                    <div class="create-character__selection">

                        <div class="create-character__race">
                            <div *ngFor="let race of races; let i = index" class="create-character__race__picker"
                                (click)="setSelectedRaceIndex(i)">

                                <div class="create-character__race--card" [class.active]="selectedIndex === i"
                                    ngClass="create-character__race--card--{{race.name}}" (click)="selectRace(race)">
                                    <p>{{race.name}}</p>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="create-character__info">
                        <div>
                            <h2>{{raceHeader}}</h2>
                            <p>{{raceDescription}}</p>
                            <h2>Attributes:</h2>
                            <table>
                                <tr>
                                    <td>Strength:</td>
                                    <td>{{raceAttributes?.Strength}}</td>
                                </tr>
                                <tr>
                                    <td>Dexterity:</td>
                                    <td>{{raceAttributes?.Dexterity}}</td>
                                </tr>
                                <tr>
                                    <td>Constitution:</td>
                                    <td>{{raceAttributes?.Constitution}}</td>
                                </tr>
                                <tr>
                                    <td>Intelligence:</td>
                                    <td>{{raceAttributes?.Intelligence}}</td>
                                </tr>
                                <tr>
                                    <td>Wisdom:</td>
                                    <td>{{raceAttributes?.Wisdom}}</td>
                                </tr>
                                <tr>
                                    <td>Charisma:</td>
                                    <td>{{raceAttributes?.Charisma}}</td>
                                </tr>

                            </table>
                        </div>
                        <div>
                            <div class="create-character__raceImage">
                                <img [src]="raceImage" [alt]="raceHeader" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wizard-controls">
                    <button mat-raised-button color="primary" matStepperNext>Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step>
            <form [formGroup]="classForm">
                <div class="create-character-container">
                    <ng-template matStepLabel>Class</ng-template>
                    <div class="create-character__selection">

                        <div class="create-character__choice">
                            <div *ngFor="let class of classes; let i = index" class="create-character__race__picker"
                                (click)="setSelectedClassIndex(i)">

                                <div class="create-character__choice--card" [class.active]="selectedClassIndex === i"
                                    ngClass="create-character__choice--card--{{class.name}}"
                                    (click)="selectClass(class)">
                                    <p>{{class.name}}</p>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="create-character__info">
                        <div>
                            <h2>{{classHeader}}</h2>
                            <p>{{classDescription}}</p>
                        </div>
                        <div>
                            <div class="create-character__image">
                                <img [src]="classImage" [alt]="classHeader" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wizard-controls">
                    <button mat-raised-button color="accent" matStepperPrevious>Back</button>

                    <button mat-raised-button color="primary" matStepperNext>Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step>
            <form [formGroup]="raceForm">
                <div class="create-character-container">
                    <ng-template matStepLabel>Description</ng-template>
                    <div class="create-character__selection">


                        <app-player-appearance [form]="appearanceForm"></app-player-appearance>

                    </div>

                    <div class="create-character__info create-character__info--description">

                        <div class="create-character__description">
                            <div>
                                <span class="char-desc">Eyes:</span> &nbsp;{{eyeColor}}
                            </div>
                            <div>
                                <span class="char-desc">Hair:</span> &nbsp;{{hairColor}}
                            </div>
                            <div>
                                <span class="char-desc">Skin:</span> &nbsp;{{skinColor}}
                            </div>
                            <div>
                                <span class="char-desc"> Hair Length:</span> &nbsp;{{hairLength}}
                            </div>
                            <div>
                                <span class="char-desc"> Build:</span> &nbsp;{{build}}
                            </div>
                            <div>
                                <span class="char-desc"> Hair Texture: </span> &nbsp;{{hairTexture}}
                            </div>
                            <div>
                                <span class="char-desc">Face:</span> &nbsp;{{face}}
                            </div>
                            <div *ngIf="gender == 'Male'">
                                <span class="char-desc">Facial Hair:</span> &nbsp;{{facialHair}}
                            </div>

                        </div>
                        <p>You can add a character description in game, one is required to complete the Academy.</p>


                    </div>
                </div>
                <div class="wizard-controls">
                    <button mat-raised-button color="accent" matStepperPrevious>Back</button>

                    <button mat-raised-button color="primary" matStepperNext
                        [disabled]="appearanceForm.invalid">Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <div class="final-step">
                <p>Your character {{name}}, is ready to enter ArchaicQuest.</p>

                <!-- <p>If you need help, ask on the <a style="text-decoration: underline;"
                        href="https://discord.gg/nuf7FVq">discord channel</a> or in
                    game using the newbie channel e.g 'newbie how do I drink a potion?'</p> -->

                <p>3 simple rules to follow: </p>
                <ul>
                    <li>This is a role play encouraged MUD</li>
                    <li>Be respectful and nice in out of character communication</li>
                    <li>Report bugs</li>
                </ul>

                <p>Enjoy your time and have fun, your adventure awaits you.</p>


                <button mat-raised-button (click)="playGame()" color="primary">Enter ArchaicQuest</button>
            </div>



        </mat-step>
    </mat-horizontal-stepper>



</div>