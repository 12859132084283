<h1 mat-dialog-title>Reset Password</h1>
<form [formGroup]="form">
<div mat-dialog-content>
    <p>Your password link is valid for 24 hours, don't share it with anyone else.</p>
  
        <mat-form-field appearance="outline">
            <mat-label>New Password</mat-label>
            <input matInput required="true" placeholder="New Password" formControlName="password" type="password">

            <mat-error class="error-message"
            *ngIf="form.get('password').hasError('required') && (form.get('password').dirty || form.get('password').touched)">
            Enter a password</mat-error>

        <mat-error class="error-message"
            *ngIf="form.get('password').hasError('minlength') && (form.get('password').dirty || form.get('password').touched)">
            Password needs to be at least 6 characters long</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Confirm New Password</mat-label>
            <input matInput required="true" placeholder="New Password" formControlName="confirmPassword" type="password">

            <mat-error class="error-message"  *ngIf="form.get('confirmPassword').hasError('required') && (form.get('confirmPassword').dirty || form.get('confirmPassword').touched)">
            Reenter the password</mat-error>
        <mat-error class="error-message"  *ngIf="form.get('confirmPassword').hasError('minlength') && (form.get('confirmPassword').dirty || form.get('confirmPassword').touched)">
            Password needs to be at least 6 characters long</mat-error>

            <mat-error class="error-message"  *ngIf="form.get('confirmPassword').hasError('matching') && (form.get('confirmPassword').dirty || form.get('confirmPassword').touched)">
                Password must match</mat-error>


                
        </mat-form-field>


</div>
<div mat-dialog-actions>
   
    <button mat-raised-button color="primary" cdkFocusInitial (click)="resetPassword($event)" [disabled]="!form.valid">Reset Password</button>
</div>
</form>