<div class="welcome-container">
    <div class="welcome-log-in">
        <div class="welcome-log-in__form">
            <a class="logolink" href="https://www.archaicquest.com" target="_blank"><img
                    src="/assets/images/archaic-quest.png" alt="ArchaicQuest logo Text based game" /></a>
            <form [formGroup]="form">
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" formControlName="email" />
                    <mat-error class="error-message" *ngIf="
              form.get('email').hasError('required') &&
              (form.get('email').dirty || form.get('email').touched)
            ">
                        Enter your Email address</mat-error>

                    <mat-error class="error-message"
                        *ngIf="form.get('email').hasError('pattern') && (form.get('email').dirty || form.get('email').touched)">
                        Enter a valid email</mat-error>

                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput placeholder="Password" type="password" formControlName="password"
                        autocomplete="off" />
                    <mat-error class="error-message"
                        *ngIf="form.get('password').hasError('required') && (form.get('password').dirty || form.get('password').touched)">
                        Enter your password</mat-error>
                </mat-form-field>
           

            <button mat-raised-button color="primary" [class.spinner]="loading" (click)="signIn($event)"
                [disabled]="!form.valid">Sign in</button>
            </form>

            <p class="forgot-password"><a href="javascript:void(0)" (click)="openForgotPasswordDialog()">Forgot password</a></p>

            <div class="or">
                <hr class="bar" />
                <span>OR</span>
                <hr class="bar" />
            </div>

            <button class="ghost-button" mat-stroked-button color="primary" (click)="openDialog()">
                Create your account
            </button>
        </div>
    </div>

    <div class="welcome-intro">
        <h1>ArchaicQuest <span>A text based RPG | Multi-User Dungeon</span></h1>

        <p class="welcome-tag-line">
            Explore, discover, and conquer in ArchaicQuest an online RPG where the only limits are your imagination.
            Being text based allows for a rich environment to role play your desires.
            You choose your own path, and set your own destiny.
        </p>

        <button class="ghost-button" mat-stroked-button color="primary" (click)="openDialog()">
            Create your account
        </button>
    </div>
</div>