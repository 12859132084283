<h1 mat-dialog-title>Sign up</h1>
<form [formGroup]="form">
<div mat-dialog-content>
    <p>A step away from exploring dungeons, looting corpses, and becoming the next hero. </p>
 
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" formControlName="email">

            <mat-error class="error-message"
                *ngIf="form.get('email').hasError('pattern') && (form.get('email').dirty || form.get('email').touched)">
                Enter a valid email</mat-error>

        </mat-form-field>
  
        <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput placeholder="Password" type="password" formControlName="password" >
            <mat-error class="error-message"
                *ngIf="form.get('password').hasError('required') && (form.get('password').dirty || form.get('password').touched)">
                Enter a password</mat-error>
            <mat-error class="error-message"
                *ngIf="form.get('password').hasError('minlength') && (form.get('password').dirty || form.get('password').touched)">
                Password needs to be atleast 6 characters long</mat-error>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <input matInput required="true" placeholder="Confirm Password" type="password"  formControlName="confirmPassword">

            <mat-error class="error-message"  *ngIf="form.get('confirmPassword').hasError('required') && (form.get('confirmPassword').dirty || form.get('confirmPassword').touched)">
            Reenter the password</mat-error>
        <mat-error class="error-message"  *ngIf="form.get('confirmPassword').hasError('minlength') && (form.get('confirmPassword').dirty || form.get('confirmPassword').touched)">
            Password needs to be at least 6 characters long</mat-error>

            <mat-error class="error-message"  *ngIf="form.get('confirmPassword').hasError('matching') && (form.get('confirmPassword').dirty || form.get('confirmPassword').touched)">
                Password must match</mat-error>


                
        </mat-form-field>

</div>
<div mat-dialog-actions>
    <small>We won't spam you or sell your information. E-mail required for account verification and password recovery
        only.
        If signing up for the newsletter this will be sporadic and no more than monthly.</small>
    <button mat-raised-button color="primary" cdkFocusInitial (click)="signUp($event)" [disabled]="!form.valid">Sign
        Up</button>
</div>
</form>