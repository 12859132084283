<h1 mat-dialog-title>Forgot Password</h1>
<form [formGroup]="form">
<div mat-dialog-content>
    <p>If your email is in our system you will receive an email with a password reset link.</p>
   
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput required="true" placeholder="Email" formControlName="email">

            <mat-error class="error-message"
                *ngIf="form.get('email').hasError('pattern') && (form.get('email').dirty || form.get('email').touched)">
                Enter a valid email</mat-error>

        </mat-form-field>
    
</div>
<div mat-dialog-actions>
   
    <button mat-raised-button type="submit" color="primary" cdkFocusInitial (click)="resetPassword($event)" [disabled]="!form.valid">Reset Password</button>
</div>
</form>